import React, { useState } from "react";
import { Box, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SystemConstant } from "const";
import { EditOutlined, CancelOutlined, AnalyticsOutlined, DeleteOutlined } from "@mui/icons-material";
import EditMessageDialog from "./EditMessageDialog";
import { useBotMessageUpdate } from "hooks";
import MessageStatisticDialog from "./MessageStatisticDialog";

const MessageAction = ({ message, refetchList }) => {
  const classes = useStyles();
  const status = message ? message.status : SystemConstant.BOT_MESSAGE_STATUS.PENDING.status;

  const { updateBotMessage } = useBotMessageUpdate();

  const [isOpenEditMessage, setIsOpenEditMessage] = useState(false);
  const [isOpenMessageStatistic, setIsOpenMessageStatistic] = useState(false);

  const handleCancle = () => {
    updateBotMessage({
      bot_message_id: message.id,
      status: SystemConstant.BOT_MESSAGE_STATUS.CANCELED.status,
      onClose: () => {
        refetchList();
      },
    });
  };

  const handleDelete = () => {
    updateBotMessage({
      bot_message_id: message.id,
      state: SystemConstant.STATE.DELETED,
      onClose: () => {
        refetchList();
      },
    });
  };

  const StatusLabel = () => {
    switch (status) {
      case SystemConstant.BOT_MESSAGE_STATUS.PENDING.status:
        return (
          <Box className={classes.container}>
            <EditOutlined
              className={classes.iconButton}
              onClick={() => {
                setIsOpenEditMessage(true);
              }}
            />
            <CancelOutlined className={classes.iconButton} onClick={handleCancle} />
          </Box>
        );
      case SystemConstant.BOT_MESSAGE_STATUS.SUCCESS.status:
        return (
          <Box className={classes.container}>
            <AnalyticsOutlined
              className={classes.iconButton}
              onClick={() => {
                setIsOpenMessageStatistic(true);
              }}
            />
          </Box>
        );
      case SystemConstant.BOT_MESSAGE_STATUS.CANCELED.status:
        return (
          <Box className={classes.container}>
            <Box
              className={classes.boxAction}
              onClick={() => {
                setIsOpenEditMessage(true);
              }}
            >
              Gửi lại
            </Box>
            <DeleteOutlined className={classes.iconButton} onClick={handleDelete} />
          </Box>
        );
      case SystemConstant.BOT_MESSAGE_STATUS.DRAFT.status:
        return (
          <Box className={classes.container}>
            <Box
              className={classes.boxAction}
              onClick={() => {
                setIsOpenEditMessage(true);
              }}
            >
              Tiếp tục
            </Box>
          </Box>
        );
      default:
        return <Chip label={SystemConstant.BOT_MESSAGE_STATUS.FAILED.label} color="error" className={classes.status} />;
    }
  };

  return (
    <Box className={classes.container}>
      <StatusLabel />

      {message && (
        <EditMessageDialog
          message={message}
          open={isOpenEditMessage}
          onClose={() => {
            setIsOpenEditMessage(false);
          }}
          refetchList={refetchList}
        />
      )}

      {message && (
        <MessageStatisticDialog
          message={message}
          open={isOpenMessageStatistic}
          onClose={() => {
            setIsOpenMessageStatistic(false);
          }}
        />
      )}
    </Box>
  );
};

export default MessageAction;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
  },

  status: {
    cursor: "default",
    width: "130px",
    position: "static",
  },

  iconButton: {
    border: "1px solid #D4D5D8",
    borderRadius: "8px",
    width: "32px",
    height: "32px",
    backgroundColor: "#DFDFDF",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 5px 3px #D4D5D8",
      width: "40px",
      height: "40px",
    },
  },

  boxAction: {
    border: "1px solid #D4D5D8",
    borderRadius: "8px",
    width: "62px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 5px 3px #D4D5D8",
      width: "68px",
      height: "40px",
    },
  },
}));
