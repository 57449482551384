import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { DatetimeUtils } from "utils";
import MessageStatus from "./MessageStatus";
import MessageAction from "./MessageAction";

const Datatable = ({ botMessageList, isLoading, pagination, onChangePage, refetch }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const getShowTime = (message) => {
    if (message.time && message.time > 0) {
        return DatetimeUtils.convertMillisecondsToDateTime(message.time)
    }
    if (message.modified && message.modified > 0) {
        return DatetimeUtils.convertMillisecondsToDateTime(message.modified)
    }
    return DatetimeUtils.convertMillisecondsToDateTime(message.created)
  }

  return (
    <Fragment>
      <TableContainer sx={{ my: 4, height: "530px" }}>
        <Table stickyHeader sx={{ minWidth: 650 }} className={classes.boxTable}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "40%", fontWeight: "bold" }}>Tin nhắn</TableCell>
              <TableCell sx={{ width: "20%", fontWeight: "bold" }} align="center">
                Trạng thái
              </TableCell>
              <TableCell sx={{ width: "20%", fontWeight: "bold" }} align="center">
                Thời gian
              </TableCell>
              <TableCell sx={{ width: "20%", fontWeight: "bold" }} align="center">
                Thao tác
              </TableCell>
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <small>{getLabel(LangConstant.TXT_LOADING)}</small>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {botMessageList?.length > 0 ? (
                botMessageList.map((message, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.name}>{message.content}</TableCell>
                    <TableCell className={classes.name} align="center">
                      <MessageStatus status={message.status} />
                    </TableCell>
                    <TableCell align="center">{getShowTime(message)}</TableCell>
                    <TableCell className={classes.name} align="center">
                      <MessageAction message={message} refetchList={refetch}/>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <small>
                      <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                    </small>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pagination.total_pages > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
          <Pagination
            count={pagination.total_pages}
            onChange={onChangePage}
            page={pagination.offset / pagination.limit + 1}
            className={classes.pagination}
            color="primary"
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Fragment>
  );
};

export default Datatable;

const useStyles = makeStyles(() => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
  },

  boxTable: {
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },

  name: {
    whiteSpace: "wrap",
  },
}));
