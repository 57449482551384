import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import HumpUtils from "humps";
import { ApiConstant, KeyConstant, LangConstant } from "const";
import { ApiUtils, StorageUtils, SystemUtils } from "utils";

export default function useDepartmentDetails() {
  const { t: getLabel } = useTranslation();

  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const departmentIdParam = searchParams.get("department_id") || "";

  const [departmentId, setDepartmentId] = useState(departmentIdParam);
  const [department, setDepartment] = useState(null);
  const [positions, setPositions] = useState([]);

  const positionListQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getTotalPositionList, departmentId],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const paging = 1; // 1 for pagination | 0 for get all
      const limit = 10000;
      const filter = JSON.stringify(
        HumpUtils.decamelizeKeys({ unitId: department.unitId, departmentId: departmentId }),
      );
      const asc = 0;
      const offset = 0;
      const orderBy = "created";
      const payload = { branchId, paging, limit, filter, asc, offset, orderBy };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_LIST_POSITIONS,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onSuccess: response => {
      setPositions(response?.data?.data || []);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const departmentDetailQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getDepartmentDetails],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const payload = { branchId, departmentId };
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_DEPARTMENT_DETAILS,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onSuccess: response => {
      setDepartment(response.data);
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const handleGetDepartmentDetails = id => {
    setDepartmentId(id);
  };

  useEffect(() => {
    if (departmentId) departmentDetailQuery.refetch();
  }, [departmentId]);

  useEffect(() => {
    if (department) positionListQuery.refetch();
  }, [department]);


  return {
    isLoading: positionListQuery.isLoading || departmentDetailQuery.isLoading,
    department,
    positions,
    handleGetDepartmentDetails,
  };
}
