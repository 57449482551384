// Common Constant
export const BASE_URL = window.env.BASE_URL;
export const BRANCH_DOMAIN = window.env.BRANCH_DOMAIN;
export const TIMEOUT = 30000;
export const HEADER_DEFAULT = {
  Accept: "application/json",
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  "trios-api-version": 2,
};

export const REACT_QUERY_KEYS = {
  // Branch Management
  getBranchList: "getBranchList",
  getBranchDetails: "getBranchDetails",

  // System Tracking
  getSystemErrors: "getSystemErrors",
  getUserActivities: "getUserActivities",

  // User Management
  getUserList: "getUserList",
  getAccount: "getAccount",

  // Unit Management
  getUnitList: "getUnitList",
  getTotalUnitList: "getTotalUnitList",
  getUnitDetails: "getUnitDetails",

  // Department Management
  getDepartmentList: "getDepartmentList",
  getDepartmentDetails: "getDepartmentDetails",

  // Position Management
  getPositionList: "getPositionList",
  getTotalPositionList: "getTotalPositionList",
  getPositionListByUnitId: "getPositionListByUnitId",

  // Check Health
  checkHealth: "checkHealth",

  // Branch Request Management
  getBranchRequestList: "getBranchRequestList",

  // Bot Message Management
  getBotMessageList: "getBotMessageList",

  //Tus
  getTusAvatar: "getTusAvatar",
};

// HTTP Status
export const STT_OK = 200;
export const STT_UNAUTHORIZED = 401;
export const STT_FORBIDDEN = 403;
export const STT_NOT_FOUND = 404;
export const STT_INTERNAL_SERVER = 500;
export const STT_SMART_OTP_WRONG = 400;
export const STT_SMART_OTP_EXPIRED = 402;
export const STT_SMART_OTP_LIMIT = 403;
export const STT_SMART_OTP_BLOCKED = 405;
export const STT_BAD_REQUEST = 400;

// Authentication Api Endpoints
export const LOGIN = "/user/login.json";
export const CHANGE_PASSWORD = "/user/password.json";
export const VERIFY_SMART_OTP = "/user/verify.json";

// User Management Api Endpoints
export const GET_LIST_USERS = "/accounts/branch/sync.json";
export const GET_USER = "/accounts/get.json";
export const UPDATE_USER_INFO = "/accounts/branch/update.json";
export const SEARCH_USER = "/accounts/filter.json";
export const ADD_USERS = "/accounts/branch/create.json";
export const ACTIVE_USER = "/accounts/active/update.json";
export const RESET_PASSWORD = "/accounts/password/reset.json";
export const IMPORT_USER = "/accounts/branch/import.json";
export const UPDATE_USER_ACCOUNT = "/accounts/update.json";
export const CREATE_ACCOUNT = "/accounts/create.json";

// Branch Management Api Endpoints
export const BRANCH_INFORMATION = "/branch/get.json";
export const UPLOAD_AVATAR = "/branch/upload.json";
export const UPDATE_BRANCH = "/branch/update.json";
export const CREATE_BRANCH = "/branch/add.json";
export const DELETE_BRANCH = "/user/branch/update.json";
export const GET_BRANCH_ERRORS = "/branch/error/filter.json";
export const GET_USER_ACTIVITIES = "/branch/action/filter.json";

// Handle File Api Endpoints
export const GET_FILE_ATTACHMENT = "/file/read.json";

// Create User Activities Logs
export const CREATE_USER_ACTIVITIES_LOG = "/branch/action/add.json"; // Todo: Update this API path

// Unit Api Endpoints
export const GET_UNIT_LIST = "/units/list.json";
export const GET_UNIT_DETAILS = "/units/get.json";
export const POST_CREATE_UNIT = "/units/create.json";
export const POST_UPDATE_UNIT = "/units/update.json";
export const POST_DELETE_UNIT = "/units/delete.json";
export const POST_CREATE_UNIT_CHANNEL = "/units/channel/create.json";

// Department Api Endpoints
export const GET_DEPARTMENT_LIST = "/departments/list.json";
export const GET_DEPARTMENT_DETAILS = "/departments/get.json";
export const POST_CREATE_DEPARTMENT = "/departments/create.json";
export const POST_UPDATE_DEPARTMENT = "/departments/update.json";
export const POST_DELETE_DEPARTMENT = "/departments/delete.json";
export const ADD_USERS_TO_DEPARTMENT = "departments/users/add.json";
export const DELETE_DEPARTMENT_USERS = "departments/users/delete.json";
export const POST_CREATE_DEPARTMENT_CHANNEL = "/departments/channel/create.json";

// Position Api Endpoints
export const GET_LIST_POSITIONS = "/positions/list.json";
export const GET_POSITION = "/positions/get.json";
export const CREATE_POSITION = "/positions/create.json";
export const UPDATE_POSITION = "/positions/update.json";
export const DELETE_POSITION = "/positions/delete.json";

//Check health
export const CHECK_HEALTH = "/check/heath.json";

//Bot message
export const CREATE_BOT_MESSAGE = "/bot/message/create.json";
export const GET_BOT_MESSAGE_LIST = "/bot/message/get.json";
export const UPDATE_BOT_MESSAGE = "/bot/message/update.json";

//QR
export const CREATE_BRANCH_QR = "/branch/qr/get.json";

//Branch Request
export const GET_BRANCH_REQUEST_LIST = "/branch/request/list.json";
export const POST_BRANCH_REQUEST_UPDATE = "/branch/request/update.json";
