import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import localforage from "localforage";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, CommonUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import qs from "qs";

const useBranchRequestList = props => {
  const { t: getLabel } = useTranslation();

  const branchRequestStatus = {
    all: { title: getLabel("OBJ_USER_FILTER_STATUS.all"), status: -1, order: 0 },
    pendding: { title: "Chờ phê duyệt", status: 0, order: 1 },
    accepted: { title: "Đã chấp nhận", status: 1, order: 2 },
    rejected: { title: "Đã từ chối", status: 2, order: 3 },
  };

  const maxRowPerPage = 10;
  const { page, filter, status } = props;

  const statusIds = status < 0 ? [branchRequestStatus.pendding.status, branchRequestStatus.rejected.status] : [status];
  const filterJson = JSON.stringify({ name: filter });

  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getBranchRequestList, page, status],

    queryFn: async () => {
      const apiPayload = {
        paging: 1,
        limit: maxRowPerPage,
        filter: filterJson,
        asc: 0,
        offset: (page - 1) * maxRowPerPage,
        orderBy: "created",
        state: 1,
        status_ids: statusIds,
      };
      let response = await ApiUtils.createApiWithToken(true).post(
        ApiConstant.GET_BRANCH_REQUEST_LIST,
        qs.stringify(HumpUtils.decamelizeKeys(apiPayload)),
      );

      return response.data.data;
    },

    onSuccess: branchRequest => {},

    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const { data: branchRequestList, ...pagination } = data || {};

  return {
    branchRequestList: branchRequestList || [],
    pagination: pagination || {},
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export default useBranchRequestList;
