import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { Add, PermIdentityOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { useCreateBotMessage, useBotMessageList } from "hooks";
import { Box, Button, Typography } from "@mui/material";
import { SystemUtils } from "utils";
import { PageTitle } from "components";
import { CreateMessageDialog, Datatable, BotAccountDialog } from "./components";

const MediaManagement = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  const { createBotMessage } = useCreateBotMessage();

  const [actionDialogConfig, setActionDialogConfig] = useState({ open: false, position: null });
  const [isOpenBotAccount, setIsOpenBotAccount] = useState(false);
  const [page, setPage] = useState(1);

  const { botMessageList, pagination, refetch, isFetching } = useBotMessageList({
    page: page,
  });

  const onChangePage = (e, page) => {
    setPage(page);
  };

  const handleCloseActionDialog = () => {
    setActionDialogConfig({ open: false, position: null });
  };

  const handleCreateMessage = (content, time) => {
    if (!Boolean(content)) {
      SystemUtils.appendNotification("Nội dung tin nhắn không được trống!", "error");
    } else {
      createBotMessage({
        content: content,
        time: time,
        onClose: () => {
          handleCloseActionDialog();
          refetch();
        },
      });
    }
  };

  const handleDraftMessage = content => {
    if (!Boolean(content)) {
      SystemUtils.appendNotification("Nội dung tin nhắn không được trống!", "error");
    } else {
      createBotMessage({
        content: content,
        onClose: () => {
          handleCloseActionDialog();
          refetch();
        },
      });
    }
  };

  const handleOpenCreateMessageDialog = () => {
    setActionDialogConfig({ open: true, position: null });
  };

  return (
    <Fragment>
      <PageTitle>{getLabel(titleObjectContent.mediaManagement)}</PageTitle>
      <Paper sx={{ px: 2, py: 4, paddingTop: "20px" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "20px" }}> Danh sách tin nhắn </Typography>
          <Box className={classes.btnContainer}>
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<PermIdentityOutlined fontSize="small" />}
              onClick={() => {
                setIsOpenBotAccount(true);
              }}
            >
              Thông tin tài khoản
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<Add fontSize="small" />}
              onClick={handleOpenCreateMessageDialog}
            >
              Soạn tin nhắn mới
            </Button>
          </Box>
        </Box>

        <Datatable
          botMessageList={botMessageList}
          pagination={pagination}
          onChangePage={onChangePage}
          isLoading={isFetching}
          refetch={refetch}
        />

        <CreateMessageDialog
          open={actionDialogConfig.open}
          onClose={handleCloseActionDialog}
          onCreate={handleCreateMessage}
          onDraft={handleDraftMessage}
        />

        <BotAccountDialog
          open={isOpenBotAccount}
          onClose={() => {
            setIsOpenBotAccount(false);
          }}
        />
      </Paper>
    </Fragment>
  );
};

export default MediaManagement;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 8,
  },

  btnContainer: {
    display: "flex",
    gap: 8,
  },

  filterContainer: {
    display: "flex",
    gap: 8,
  },

  textField: {
    width: "400px",
  },

  button: {
    height: "40px",
  },
}));
