import { KeyConstant } from "const";
import { getPermanenceData, getStoreData, storeData, storePermanenceData } from "./storage.utils";
import { HumpUtils } from "utils";

export const handlePersistBranch = (branch, isChangeAccessToken = true) => {
  const isRemember = getStoreData(KeyConstant.KEY_REMEMBER_KEY);
  const branchOptions = JSON.parse(branch.options) || {};

  storeData(KeyConstant.KEY_BRANCH_ID, branch.id, isRemember);
  storeData(KeyConstant.KEY_BRANCH_TYPE, branch.type, isRemember);
  storeData(KeyConstant.KEY_CURRENT_DOMAIN, branch.domain, isRemember);
  storeData(KeyConstant.KEY_BRANCH_MODE, branch.branchMode, isRemember);
  storeData(KeyConstant.KEY_LOGIN_MODE, branch.loginMode, isRemember);
  storeData(KeyConstant.KEY_BRANCH_ATTACHMENT, branch.attachmentId, isRemember);
  storePermanenceData(KeyConstant.KEY_BRANCH_INFORMATION, branchOptions?.information, isRemember);
  storeData(KeyConstant.KEY_BRANCH_QR_EXPIRY_TIME, branchOptions?.qr_expiry_time, isRemember);
  storeData(KeyConstant.KEY_TUS_DOMAIN, branchOptions?.tus_domain, isRemember);
  if (isChangeAccessToken) storeData(KeyConstant.KEY_ACCESS_TOKEN, branch.accessToken, isRemember);
};

export const getCurrentBranch = () => {
  const currentBranchId = getStoreData(KeyConstant.KEY_BRANCH_ID);
  const branchList = getStoreData(KeyConstant.KEY_BRANCH_LIST);
  const currentBranch = branchList.find(item => item.id === currentBranchId);
  try {
    currentBranch.options = JSON.parse(getPermanenceData(currentBranchId) || "{}");
  } catch (error) {
    currentBranch.options = {};
  }
  return currentBranch;
};

export const sortBranchByName = branches => {
  branches.sort((prev, next) => {
    let prevBranchName = prev?.name?.toLowerCase();
    let nextBranchName = next?.name?.toLowerCase();
    if (prevBranchName < nextBranchName) return -1;
    if (prevBranchName > nextBranchName) return 1;
    return 0;
  });
  return branches;
};

export const handleUpdateBranchList = branch => {
  branch = HumpUtils.decamelizeKeys(branch);
  const isRemember = getStoreData(KeyConstant.KEY_REMEMBER_KEY);
  const currentBranchList = getStoreData(KeyConstant.KEY_BRANCH_LIST);
  const newBranchList = currentBranchList.map(currentBranch => {
    if (currentBranch.id === branch.id) return branch;
    return currentBranch;
  });
  storeData(KeyConstant.KEY_BRANCH_LIST, newBranchList, isRemember);
};
